import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  data: {},
  wallet: 0,
  onboarding: isEmpty(localStorage.getItem("onboarding")) && true,
  notification_home: true,
  merchant_bank: [],
};

const profileReducer = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.data = action.payload;
      state.wallet = action.payload.merchant_wallet?.total || 0;
    },
    stopOnboarding: (state, action) => {
      state.onboarding = false;
      localStorage.setItem("onboarding", false);
    },
    stopNotification: (state, action) => {
      state.notification_home = false;
    },
    setBank: (state, action) => {
      state.merchant_bank = action.payload;
    },
  },
});

export const { setAccount, stopOnboarding, stopNotification, setBank } =
  profileReducer.actions;

export default profileReducer.reducer;
