import React, { useState, useEffect } from "react";
import { Layout as TheLayout } from "antd";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Content from "./Content";
import { requestGetInitializer } from "redux/action/home";

const Layout = (props) => {
  const [refreshMenu, setRefreshMenu] = useState(false);

  useEffect(() => {
    requestGetInitializer();
  }, []);

  const handleRefresh = () => {
    setRefreshMenu(true);
    setTimeout(() => setRefreshMenu(false), 20);
  };

  return (
    <TheLayout>
      <Header handleRefresh={handleRefresh} />
      <TheLayout>
        <Sidebar handleRefresh={handleRefresh} refresh={refreshMenu} />
        <TheLayout className="content-scroll">
          <Content {...props} />
        </TheLayout>
      </TheLayout>
    </TheLayout>
  );
};

export default Layout;
