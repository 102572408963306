import { lazy } from "react";

const Ulasan = lazy(() => import("containers/ulasan"));
const DetailUlasan = lazy(() => import("containers/ulasan/detail"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/ulasan",
    component: Ulasan,
  },
  {
    path: "/ulasan/detail-ulasan/:id",
    component: DetailUlasan,
  },
];
