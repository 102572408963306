import React from "react";
import { Breadcrumb, Layout, Row, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";
const { Content: TheContent } = Layout;
const { Text } = Typography;

const Content = (props) => {
  const { children } = props;
  const location = useLocation();
  const pathname = location.pathname.split("/").filter((i) => i);

  const extraBreadcrumbItems = pathname.map((name, index) => {
    const url = `/${pathname.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        {index > 0 ? (
          <Text
            style={{
              textTransform: "capitalize",
              color: "#EA217A",
              opacity: 0.8,
            }}
          >
            {name?.replaceAll("-", " ")}
          </Text>
        ) : (
          <Link to={url} style={{ textTransform: "capitalize" }}>
            {name?.replaceAll("-", " ")}
          </Link>
        )}
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <TheContent>
      {location.pathname !== "/" && (
        <Row style={{ height: "fit-content" }}>
          <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
        </Row>
      )}
      {children}
    </TheContent>
  );
};

export default Content;
