import { createSlice } from "@reduxjs/toolkit";
import { WAITING_CONFIRMATION } from "constants/order";

const initialState = {
  list: {
    loading: false,
    data: [],
    page: 1,
    totalData: null,
    totalPage: null,
    tab: WAITING_CONFIRMATION,
    error: "",
  },
  index: {
    canceled: null,
    complaint: null,
    finish: null,
    shipping: null,
    waiting_confirmation: null,
    waiting_pickup: null,
  },
  detail: {
    loading: true,
    data: {},
    error: "",
  },
  trackingShipment: {
    loading: true,
    data: {},
    error: "",
  },
  pickupSummaries: {
    loading: true,
    data: {},
    error: "",
  },
  onAction: {
    loading: false,
  },
};

const orderReducer = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCounter: (state, action) => {
      state.index = action.payload;
    },
    GET_ORDER_LOADING: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    },
    GET_ORDER_SUCCESS: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data,
          page: action.payload.page,
          totalData: Number(action.payload.totalData),
          totalPage: Number(action.payload.totalPage),
        },
      };
    },
    GET_ORDER_FAILED: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      };
    },
    setTab: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          tab: action.payload,
        },
      };
    },
    GET_ORDER_DETAIL_LOADING: (state, action) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    },
    GET_ORDER_DETAIL_SUCCESS: (state, action) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.payload.data,
        },
      };
    },
    GET_ORDER_DETAIL_FAILED: (state, action) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    },
    GET_TRACKING_SHIPMENT_LOADING: (state, action) => {
      return {
        ...state,
        trackingShipment: {
          ...state.trackingShipment,
          loading: true,
        },
      };
    },
    GET_TRACKING_SHIPMENT_SUCCESS: (state, action) => {
      return {
        ...state,
        trackingShipment: {
          ...state.trackingShipment,
          loading: false,
          data: action.payload.data,
        },
      };
    },
    GET_TRACKING_SHIPMENT_FAILED: (state, action) => {
      return {
        ...state,
        trackingShipment: {
          ...state.trackingShipment,
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    },

    GET_PICKUP_SUMMARIES_LOADING: (state, action) => {
      return {
        ...state,
        pickupSummaries: {
          ...state.pickupSummaries,
          loading: true,
        },
      };
    },
    GET_PICKUP_SUMMARIES_SUCCESS: (state, action) => {
      return {
        ...state,
        pickupSummaries: {
          ...state.pickupSummaries,
          loading: false,
          data: action.payload,
        },
      };
    },
    GET_PICKUP_SUMMARIES_FAILED: (state, action) => {
      return {
        ...state,
        pickupSummaries: {
          ...state.pickupSummaries,
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    },

    ON_ACTION_LOADING: (state) => {
      return {
        ...state,
        onAction: {
          ...state.onAction,
          loading: true,
        },
      };
    },
    ON_ACTION_SUCCESS: (state) => {
      return {
        ...state,
        onAction: {
          ...state.onAction,
          loading: false,
        },
      };
    },
    ON_ACTION_FAILED: (state) => {
      return {
        ...state,
        onAction: {
          ...state.onAction,
          loading: false,
        },
      };
    },
  },
});

export const {
  setCounter,
  setTab,
  GET_ORDER_LOADING,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILED,
  GET_ORDER_DETAIL_LOADING,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_FAILED,
  GET_TRACKING_SHIPMENT_LOADING,
  GET_TRACKING_SHIPMENT_SUCCESS,
  GET_TRACKING_SHIPMENT_FAILED,
  GET_PICKUP_SUMMARIES_LOADING,
  GET_PICKUP_SUMMARIES_SUCCESS,
  GET_PICKUP_SUMMARIES_FAILED,
  ON_ACTION_LOADING,
  ON_ACTION_SUCCESS,
  ON_ACTION_FAILED,
} = orderReducer.actions;

export default orderReducer.reducer;
