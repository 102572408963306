import React from "react";
import LogoWisMerchant from "resources/img/logo-merchant.png";
import { Badge, Col, Layout, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import History from "helpers/History";
const { Header: TheHeader } = Layout;
const { Text } = Typography;

const Header = (props) => {
  const { handleRefresh } = props;
  const notification = useSelector((state) => state.home.notification);

  return (
    <TheHeader>
      <Link to="/">
        <Row className="logo-with-text">
          <Col className="img-logo">
            <img src={LogoWisMerchant} alt="logo" />
          </Col>
          <Text className="text-logo">Wis Seller</Text>
        </Row>
      </Link>
      <Badge
        count={notification?.unread}
        size="small"
        showZero
        className="notif-badge"
        onClick={() => {
          handleRefresh();
          History.push("/notification", { resetPage: true });
        }}
      >
        <Link to="/notification">
          <i className="fas fa-bell" />
        </Link>
      </Badge>
    </TheHeader>
  );
};

export default Header;
