import { message } from "antd";
import {
  URL_CREATE_MERCHANT_BANK,
  URL_DELETE_MERCHANT_BANK,
  URL_GET_BANK,
  URL_GET_COURIERS,
  URL_GET_MERCHANT_BANK,
  URL_OTP,
  URL_UPDATE_ACCOUNT_ADDRESS,
  URL_UPDATE_AVATAR,
  URL_UPDATE_EMAIL,
  URL_UPDATE_MERCHANT,
  URL_UPDATE_PHONE,
  URL_UPDATE_PIN,
  URL_UPDATE_PROFILE,
  URL_VALIDATE_BANK,
  URL_VERIFICATION_OTP,
} from "constants/setting";
import { restApi } from "helpers/Api";
import { sprintf } from "sprintf";

export const requestUpdateAccountAddress = (data, onFinish) => {
  restApi
    .patch(sprintf(URL_UPDATE_ACCOUNT_ADDRESS), data)
    .then(() => {
      message.success("Data alamat berhasil diperbarui");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};

export const requestUpdateMerchant = (data, onFinish) => {
  restApi
    .patch(sprintf(URL_UPDATE_MERCHANT), data)
    .then(() => {
      message.success("Data toko berhasil diperbarui");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};

export const requestUpdateProfile = (data, onFinish) => {
  restApi
    .patch(sprintf(URL_UPDATE_PROFILE), data)
    .then(() => {
      message.success("Data akun berhasil diperbarui");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};

export const requestUpdatePhone = (access_key, onFinish) => {
  restApi
    .patch(sprintf(URL_UPDATE_PHONE, { access_key }))
    .then(() => {
      message.success("Nomor handphone berhasil diperbarui");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};

export const requestUpdateEmail = (access_key, onFinish) => {
  restApi
    .patch(sprintf(URL_UPDATE_EMAIL, { access_key }))
    .then(() => {
      message.success("Email anda berhasil diperbarui");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};

export const requestUpdatePin = (access_key, data, onFinish) => {
  restApi
    .patch(sprintf(URL_UPDATE_PIN, { access_key }), data)
    .then(() => {
      message.success("PIN anda berhasil diperbarui");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};

export const requestOTP = (data, onFinish) => {
  restApi
    .post(sprintf(URL_OTP), data)
    .then((response) => {
      const { otp_key } = response.data.data;
      message.success(`Berhasil mengirim kode otp`);
      onFinish({ otp_key: otp_key });
    })
    .catch((error) => message.error(error.message));
};

export const requestVerificationOTP = (data, otp_key, onFinish, onFail) => {
  restApi
    .post(sprintf(URL_VERIFICATION_OTP, { otp_key }), data)
    .then((response) => {
      const { access_key } = response.data.data;
      message.success("Berhasil Verifikasi Kode OTP");
      onFinish(access_key);
    })
    .catch((error) => {
      onFail();
      message.error(error.message);
    });
};

export const requestGetBank = (action, onFinish) => {
  restApi
    .get(sprintf(URL_GET_BANK, { params: action?.params || "" }))
    .then((response) => {
      const { data } = response.data;
      onFinish(data);
    })
    .catch((error) => message.error(error.message));
};

export const requestGetMerchantBank = (action, onFinish) => {
  restApi
    .get(sprintf(URL_GET_MERCHANT_BANK, { params: action?.params || "" }))
    .then((response) => {
      const { data } = response.data;
      onFinish(data);
    })
    .catch((error) => message.error(error.message));
};

export const requestGetCouriers = (onFinish) => {
  restApi
    .get(URL_GET_COURIERS)
    .then((response) => {
      const { data } = response.data;
      onFinish(data);
    })
    .catch((error) => message.error(error.message));
};

export const requestDeleteMerchantBank = (id, onFinish) => {
  restApi
    .delete(sprintf(URL_DELETE_MERCHANT_BANK, { id }))
    .then(() => {
      message.success("Berhasil menghapus bank merchant");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};

export const requestValidateBank = (data, valid, inValid) => {
  restApi
    .post(sprintf(URL_VALIDATE_BANK), data)
    .then((response) => {
      const { data } = response.data;
      message.success("Nomor rekening terverifikasi");
      valid(data);
    })
    .catch((error) => {
      inValid();
      message.error(error.message);
    });
};

export const requestCreateMerchantBank = (data, onFinish) => {
  restApi
    .post(sprintf(URL_CREATE_MERCHANT_BANK), data)
    .then(() => {
      message.success("Berhasil menyimpan rekening bank baru");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};

export const requestUpdateAvatar = (data, onFinish) => {
  restApi
    .patch(sprintf(URL_UPDATE_AVATAR), data)
    .then(() => {
      message.success("Berhasil memperbarui gambar profile");
      onFinish();
    })
    .catch((error) => message.error(error.message));
};
