import { message } from 'antd';
import {
   URL_CHECK_EMAIL,
   URL_CHECK_PHONE,
   URL_CHECK_PIN,
   URL_GET_DISTRICT,
   URL_GET_DISTRICT_FROM_REGENCY,
   URL_GET_PROVINCE,
   URL_GET_REGENCY,
   URL_GET_REGENCY_FROM_PROVINCE,
} from 'constants/basic';
import { restApi } from 'helpers/Api';
import { sprintf } from 'sprintf';

export const requestGetProvince = (setProvince, action) => {
   restApi
      .get(sprintf(URL_GET_PROVINCE, { params: action?.params || '' }))
      .then((response) => {
         const { data } = response.data;
         setProvince(data);
      });
};

export const requestGetRegency = (setRegencies, action) => {
   restApi
      .get(sprintf(URL_GET_REGENCY, { params: action?.params || '' }))
      .then((response) => {
         const { data } = response.data;
         setRegencies(data);
      });
};

export const requestGetRegencyFromProvince = (id, setRegencies, action) => {
   restApi
      .get(
         sprintf(URL_GET_REGENCY_FROM_PROVINCE, {
            id,
            params: action?.params || '',
         }),
      )
      .then((response) => {
         const { data } = response.data;
         setRegencies(data);
      });
};

export const requestGetDistrict = (setDistrict, action) => {
   restApi
      .get(sprintf(URL_GET_DISTRICT, { params: action?.params || '' }))
      .then((response) => {
         const { data } = response.data;
         setDistrict(data);
      });
};

export const requestGetDistrictFromRegency = (id, setDistrict, action) => {
   restApi
      .get(
         sprintf(URL_GET_DISTRICT_FROM_REGENCY, {
            id,
            params: action?.params || '',
         }),
      )
      .then((response) => {
         const { data } = response.data;
         setDistrict(data);
      });
};

export const requestCheckPhone = (data, notfound, found) => {
   restApi
      .post(sprintf(URL_CHECK_PHONE), data)
      .then(() => notfound())
      .catch((error) => found(error));
};

export const requestCheckEmail = (data, notfound, found) => {
   restApi
      .post(sprintf(URL_CHECK_EMAIL), data)
      .then(() => {
         notfound();
         message.success('Email dapat digunakan');
      })
      .catch((error) => {
         found();
         message.error(error.message.replace('Surel', 'Email'));
      });
};

export const requestCheckPin = (data, valid, inValid) => {
   restApi
      .post(sprintf(URL_CHECK_PIN), data)
      .then(() => {
         valid();
         message.success('Pin berhasil diverifikasi');
      })
      .catch((error) => {
         message.error('PIN salah');
         inValid();
      });
};
