import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth";
import profileReducer from "./profile";
import basicReducer from "./basic";
import homeReducer from "./home";
import productReducer from "./product";
import orderReducer from "./order";
import ulasanReducer from "./ulasan";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  basic: basicReducer,
  home: homeReducer,
  product: productReducer,
  order: orderReducer,
  ulasan: ulasanReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/userLogout") {
    storage.removeItem("persist:root");
  }

  return appReducer(state, action);
};

export default rootReducer;
