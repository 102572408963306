import React, { useEffect, useState, memo } from "react";
import { Typography, Statistic } from "antd";
import { useSelector } from "react-redux";
import { ec } from "helpers/Crypto";
import { requestResendAuthOTP } from "redux/action/auth";
import { phoneNumber } from "helpers/Number";
const { Countdown } = Statistic;
const { Text } = Typography;

const OtpTime = (props) => {
  const { time, type = "phone" } = props;
  const [resendOTP, setResendOTP] = useState(false);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      setResendOTP(true);
    }, time * 1000);
  }, [resendOTP]); // eslint-disable-line

  const handleResend = () => {
    setResendOTP(!resendOTP);
    const data =
      type === "phone"
        ? {
            resend_otp: {
              phone: ec(phoneNumber(auth.phone)),
            },
          }
        : {
            resend_otp: {
              email: ec(auth.email),
            },
          };
    requestResendAuthOTP(data, auth.otp_key);
  };

  return (
    <div>
      {resendOTP ? (
        <Text className="otp-time resend" onClick={handleResend}>
          Kirim Ulang OTP
        </Text>
      ) : (
        <Text className="otp-time">
          Kirim ulang kode dalam{" "}
          <Countdown
            value={Date.now() + 1000 * time}
            format="mm:ss"
            valueStyle={{
              color: "#ea217a",
              fontSize: 14,
              margin: "0 3px",
            }}
          />
          detik
        </Text>
      )}
    </div>
  );
};

export default memo(OtpTime);
