import React from "react";
import { Button, Modal, Row, Typography } from "antd";
const { Text } = Typography;

const ModalConfirm = (props) => {
  const { visible, onCancel, onSubmit, content, cancelText, okText, loading } =
    props;

  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      className="modal-no-padding"
      style={{ maxWidth: 400 }}
      onCancel={onCancel}
      {...props}
    >
      <Row align="middle" style={{ flexDirection: "column", gap: 35 }}>
        <Text style={{ textAlign: "center", width: "90%" }}>{content}</Text>
        <Row style={{ flexWrap: "nowrap", gap: 16, width: "100%" }}>
          <Button className="btn-outline-gradient" onClick={onCancel}>
            {cancelText || "Batal"}
          </Button>
          <Button className="btn-gradient" onClick={onSubmit} loading={loading}>
            {okText || "Konfirmasi"}
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};

export default ModalConfirm;
