import { Avatar, notification } from "antd";
import { requestReadNotification } from "redux/action/home";
import { messaging } from "./Firebase";
import History from "./History";

export const getTokenFcm = async () => {
  let currentToken = "";
  try {
    currentToken = await messaging.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
  } catch (err) {}
  return currentToken;
};

const showNotification = (payload) => {
  const resource = JSON.parse(payload?.data?.resource);
  notification.open({
    key: resource?.id,
    message: payload?.notification?.title,
    description: payload?.notification?.body,
    onClick: () => {
      notification.close(resource?.id);
      requestReadNotification(resource?.id);
      switch (resource?.assignable_type) {
        case "tb_transactions":
          return History.push(
            `/daftar-pesanan/detail-pesanan/${resource?.assignable_id}`,
          );
        case "tb_product_reviews":
          return History.push(
            `/ulasan/detail-ulasan/${resource?.assignable_id}`,
          );
        case "withdrawals":
          return History.push(`/my-wallet`, {
            showModalPenarikan: true,
            id: resource?.assignable_id,
          });
        case "merchant_wallets":
          return History.push(`/my-wallet`);
        default:
          break;
      }
    },
    icon: payload?.notification?.image && (
      <Avatar shape="square" size={46} src={payload?.notification?.image} />
    ),
  });
};

export const onMessageListener = () => {
  new Promise(() => {
    messaging.onMessage((payload) => {
      showNotification(payload);
    });
  });
};
