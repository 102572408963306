import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: 'AIzaSyDRVWvyjVramF9C37ao5tCeCp4ypNzEqEk',
   authDomain: 'wispay-6ccac.firebaseapp.com',
   projectId: 'wispay-6ccac',
   storageBucket: 'wispay-6ccac.appspot.com',
   messagingSenderId: '882820797667',
   appId: '1:882820797667:web:19f216209bc3e5798861d6',
   measurementId: 'G-NZH03FQ373',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const messaging = firebase.messaging();
