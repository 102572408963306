import { lazy } from "react";

const Home = lazy(() => import("containers/home"));
const Wallet = lazy(() => import("containers/home/wallet"));
const Notification = lazy(() => import("containers/home/notification"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/my-wallet",
    component: Wallet,
  },
  {
    path: "/notification",
    component: Notification,
  },
];
