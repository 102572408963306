import { createSlice } from '@reduxjs/toolkit';
import { initialFiter } from 'constants/data';

const initialState = {
   list: {
      loading: false,
      data: [],
      page: 1,
      totalData: null,
      totalPage: null,
      error: '',
   },
   index: {
      all: null,
      active: null,
      inactive: null,
   },
   filter: { ...initialFiter, tab: 'all' },
   categories: {
      loading: false,
      data: [],
      error: '',
      page: 1,
      totalData: null,
      totalPage: null,
   },
   subCategories: {
      loading: false,
      data: [],
      error: '',
      page: 1,
      totalData: null,
      totalPage: null,
   },
   KabKota: {
      loading: false,
      data: [],
      error: '',
      page: 1,
      totalData: null,
      totalPage: null,
   },
   kecamatan: {
      loading: false,
      data: [],
      error: '',
      page: 1,
      totalData: null,
      totalPage: null,
   },
};

const productReducer = createSlice({
   name: 'product',
   initialState,
   reducers: {
      setCounter: (state, action) => {
         return {
            ...state,
            index: {
               ...state.index,
               ...action.payload,
            },
         };
      },
      setFilter: (state, action) => {
         state.filter = action.payload;
      },
      GET_PRODUCT_LOADING: (state, action) => {
         return {
            ...state,
            list: {
               ...state.list,
               loading: true,
            },
         };
      },
      GET_PRODUCT_SUCCESS: (state, action) => {
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               data: action.payload.data,
               page: action.payload.page,
               totalData: Number(action.payload.totalData),
               totalPage: Number(action.payload.totalPage),
            },
         };
      },
      GET_PRODUCT_FAILED: (state, action) => {
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               error: action.payload,
            },
         };
      },

      GET_PRODUCT_CATEGORIES_LOADING: (state, action) => {
         return {
            ...state,
            categories: {
               ...state.categories,
               loading: true,
            },
         };
      },
      GET_PRODUCT_CATEGORIES_SUCCESS: (state, action) => {
         return {
            ...state,
            categories: {
               ...state.categories,
               loading: false,
               data:
                  action.payload.page > 1
                     ? [...state.categories?.data, ...action.payload.data]
                     : action.payload.data,
               page: action.payload.page,
               totalData: Number(action.payload.totalData),
               totalPage: Number(action.payload.totalPage),
            },
         };
      },
      GET_PRODUCT_PROVINSI_LOADING: (state, action) => {
         return {
            ...state,
            provinsi: {
               ...state.provinsi,
               loading: true,
            },
         };
      },
      GET_PRODUCT_PROVINSI_SUCCESS: (state, action) => {
         return {
            ...state,
            provinsi: {
               ...state.provinsi,
               loading: false,
               data:
                  action.payload.page > 1
                     ? [...state.provinsi?.data, ...action.payload.data]
                     : action.payload.data,
               page: action.payload.page,
               totalData: Number(action.payload.totalData),
               totalPage: Number(action.payload.totalPage),
            },
         };
      },

      GET_PRODUCT_KABUPATENKOTA_LOADING: (state, action) => {
         return {
            ...state,
            KabKota: {
               ...state.KabKota,
               loading: true,
            },
         };
      },
      GET_PRODUCT_KABUPATENKOTA_SUCCESS: (state, action) => {
         return {
            ...state,
            KabKota: {
               ...state.KabKota,
               loading: false,
               data:
                  action.payload.page > 1
                     ? [...state.KabKota?.data, ...action.payload.data]
                     : action.payload.data,
               page: action.payload.page,
               totalData: Number(action.payload.totalData),
               totalPage: Number(action.payload.totalPage),
            },
         };
      },
      GET_PRODUCT_KECAMATAN_LOADING: (state, action) => {
         return {
            ...state,
            kecamatan: {
               ...state.kecamatan,
               loading: true,
            },
         };
      },
      GET_PRODUCT_KECAMATAN_SUCCESS: (state, action) => {
         return {
            ...state,
            kecamatan: {
               ...state.kecamatan,
               loading: false,
               data:
                  action.payload.page > 1
                     ? [...state.kecamatan?.data, ...action.payload.data]
                     : action.payload.data,
               page: action.payload.page,
               totalData: Number(action.payload.totalData),
               totalPage: Number(action.payload.totalPage),
            },
         };
      },

      GET_PRODUCT_SUBCATEGORIES_LOADING: (state, action) => {
         return {
            ...state,
            subCategories: {
               ...state.subCategories,
               loading: true,
            },
         };
      },
      GET_PRODUCT_SUBCATEGORIES_SUCCESS: (state, action) => {
         return {
            ...state,
            subCategories: {
               ...state.subCategories,
               loading: false,
               data:
                  action.payload.page > 1
                     ? [...state.subCategories?.data, ...action.payload.data]
                     : action.payload.data,
               page: action.payload.page,
               totalData: Number(action.payload.totalData),
               totalPage: Number(action.payload.totalPage),
            },
         };
      },
   },
});

export const {
   setCounter,
   setFilter,
   GET_PRODUCT_LOADING,
   GET_PRODUCT_SUCCESS,
   GET_PRODUCT_FAILED,
   GET_PRODUCT_CATEGORIES_LOADING,
   GET_PRODUCT_CATEGORIES_SUCCESS,
   GET_PRODUCT_SUBCATEGORIES_LOADING,
   GET_PRODUCT_SUBCATEGORIES_SUCCESS,
   GET_PRODUCT_KABUPATENKOTA_LOADING,
   GET_PRODUCT_KABUPATENKOTA_SUCCESS,
   GET_PRODUCT_KECAMATAN_LOADING,
   GET_PRODUCT_KECAMATAN_SUCCESS,
   GET_PRODUCT_PROVINSI_LOADING,
   GET_PRODUCT_PROVINSI_SUCCESS,
} = productReducer.actions;

export default productReducer.reducer;
