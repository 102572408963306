import React from "react";
import VisualUpdatePin from "resources/img/visual-update-pin.png";
import { Modal, Row, Typography } from "antd";
const { Text } = Typography;

const ModalUpdatePin = (props) => {
  const { visible } = props;

  return (
    <Modal
      visible={visible}
      footer={null}
      className="modal-no-padding update-pin"
      width={360}
      {...props}
    >
      <Row
        align="middle"
        style={{ flexDirection: "column", textAlign: "center" }}
      >
        <img
          src={VisualUpdatePin}
          alt="Visual Reminder"
          className="img-visual"
        />
        <Text strong style={{ fontSize: 16, margin: "24px 0 8px" }}>
          PIN Keamananmu Berhasil Diubah
        </Text>
        <Text className="desc">
          PIN ini bersifat rahasia. <br />
          Jangan bagikan PIN keamananmu ke siapapun.
        </Text>
      </Row>
    </Modal>
  );
};

export default ModalUpdatePin;
