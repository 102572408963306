import React, { useState } from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import PinInput from "./pinInput";
import OtpTime from "./otpTime";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { requestVerificationOTP } from "redux/action/setting";
const { Text } = Typography;

const ModalOTP = (props) => {
  const { visible, type = "phone", onSubmit, refresh } = props;
  const [otp, setOTP] = useState("");
  const [refreshPin, setRefreshPin] = useState(false);
  const [loading, setLoading] = useState(false);
  const authState = useSelector((state) => state.auth);

  const handleRefreshPin = () => {
    setRefreshPin(true);
    setTimeout(() => {
      setRefreshPin(false);
    }, 10);
  };

  useEffect(() => {
    setOTP("");
    handleRefreshPin();
  }, [visible]);

  useEffect(() => {
    setRefreshPin(refresh);
  }, [refresh]);

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      otp_verification: {
        otp_code: otp,
      },
    };
    requestVerificationOTP(
      data,
      authState.otp_key,
      (val) => {
        onSubmit(val);
        setLoading(false);
      },
      () => {
        handleRefreshPin();
        setLoading(false);
      },
    );
  };

  return (
    <Modal
      title="Kode Verifikasi"
      visible={visible}
      footer={null}
      className="modal-no-padding"
      width={400}
      {...props}
    >
      <Row
        align="middle"
        style={{ margin: "0 auto", flexDirection: "column", width: 312 }}
      >
        <Text className="text-grey" style={{ textAlign: "center" }}>
          Masukkan kode verifikasi yang kami kirim melalui{" "}
          {type === "phone" ? "SMS Ke nomor " : "Email ke "}
          <Text className="text-grey" style={{ fontWeight: 700 }}>
            {type === "phone" ? authState.phone : authState.email}
          </Text>
        </Text>
        <Row justify="center" style={{ margin: "20px 0 32px", width: "100%" }}>
          <Col
            span={24}
            onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : "")}
          >
            <PinInput
              fields={5}
              type="text"
              inputStyle={{
                fontFamily: "Source Sans Pro, sans-serif",
                borderRadius: "8px",
                width: "50px",
                height: "50px",
                border: "1px solid #DDDDDD",
                fontSize: "20px",
                textAlign: "center",
              }}
              onChange={setOTP}
              className="otp-auth"
              refresh={refreshPin}
            />
            <OtpTime time={300} type={type} />
          </Col>
        </Row>
        <Button
          className="btn-gradient"
          loading={loading}
          onClick={handleSubmit}
        >
          Verifikasi
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalOTP;
