import { lazy } from "react";

const Order = lazy(() => import("containers/order"));
const DetailOrder = lazy(() => import("containers/order/detail"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/daftar-pesanan",
    component: Order,
  },
  {
    path: "/daftar-pesanan/detail-pesanan/:id",
    component: DetailOrder,
  },
];
