import { createSlice } from "@reduxjs/toolkit";
import { initialFiter } from "constants/data";

const initialState = {
  list: {
    loading: false,
    data: [],
    page: 1,
    totalData: 0,
    totalPage: 1,
  },
  detail: {
    loading: false,
    data: {},
  },
  counter: {},
  filter: initialFiter,
};

const ulasanReducer = createSlice({
  name: "ulasan",
  initialState,
  reducers: {
    GET_ULASAN_LOADING: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    },
    setFilterUlasan: (state, action) => {
      state.filter = action.payload;
    },
    GET_ULASAN_SUCCESS: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.data,
          page: action.payload.page,
          totalData: Number(action.payload.totalData),
          totalPage: Number(action.payload.totalPage),
        },
      };
    },
    GET_DETAIL_ULASAN_LOADING: (state, action) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    },
    GET_DETAIL_ULASAN_SUCCESS: (state, action) => {
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.payload,
        },
      };
    },
    setCounter: (state, action) => {
      return {
        ...state,
        counter: action.payload,
      };
    },
  },
});

export const {
  GET_ULASAN_LOADING,
  GET_ULASAN_SUCCESS,
  GET_DETAIL_ULASAN_LOADING,
  GET_DETAIL_ULASAN_SUCCESS,
  setCounter,
  setFilterUlasan,
} = ulasanReducer.actions;

export default ulasanReducer.reducer;
