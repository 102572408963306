import { lazy } from "react";

const Store = lazy(() => import("containers/setting/store"));
const Alamat = lazy(() => import("containers/setting/address"));
const CreateAlamat = lazy(() => import("containers/setting/address/create"));
const EditAlamat = lazy(() => import("containers/setting/address/edit"));
const Account = lazy(() => import("containers/setting/account"));
const Other = lazy(() => import("containers/setting/account/other"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/pengaturan-toko",
    component: Store,
  },
  {
    path: "/pengaturan-toko/ubah-alamat",
    component: Alamat,
  },
  {
    path: "/pengaturan-toko/ubah-alamat/alamat-baru",
    component: CreateAlamat,
  },
  {
    path: "/pengaturan-toko/ubah-alamat/:id",
    component: EditAlamat,
  },
  {
    path: "/pengaturan-akun",
    component: Account,
  },
  {
    path: "/pengaturan-akun/ubah-telepon",
    component: Other,
  },
  {
    path: "/pengaturan-akun/ubah-email",
    component: Other,
  },
  {
    path: "/pengaturan-akun/tambah-rekening",
    component: Other,
  },
];
