import auth from "pages/auth";
import home from "pages/home";
import order from "pages/order";
import product from "pages/product";
import setting from "pages/setting";
import ulasan from "pages/ulasan";

const route = [...auth];

const privateRoute = [...home, ...product, ...order, ...ulasan, ...setting];

export { route, privateRoute };
