/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Menu as TheMenu } from 'antd';
import { menuItem } from './_menuItem';
import History from 'helpers/History';
import { useLocation } from 'react-router-dom';
import { requestLogout } from 'redux/action/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setAccToken, setOTP, userLogout } from 'redux/reducer/auth';
import ModalPin from 'components/general/modalPin';
import { dc, ec } from 'helpers/Crypto';
import { requestCheckPin } from 'redux/action/basic';
import ModalOTP from 'components/general/modalOTP';
import { requestOTP, requestUpdatePin } from 'redux/action/setting';
import ModalUpdatePin from 'components/general/modalUpdatePin';
import ModalConfirm from 'components/general/modalConfirm';
const Menu = (props) => {
   const { refreshMenu } = props;
   const [modal, setModal] = useState({
      pin: false,
      otp: false,
      update_pin: false,
      logout: false,
   });
   const [refresh, setRefresh] = useState(false);
   const [pinProps, setPinProps] = useState({
      type: 'one-time',
      mode: 'normal',
   });
   const [currentPin, setCurrentPin] = useState('');
   const [, setMenuProps] = useState({});
   const profile = useSelector((state) => state.profile.data);
   const authState = useSelector((state) => state.auth);
   const location = useLocation();
   const dispatch = useDispatch();
   const path = location.pathname.split('/');

   const handleRefresh = () => {
      setRefresh(true);
      setTimeout(() => setRefresh(false), 50);
   };

   const handleMenu = (menu) => {
      setMenuProps(menu);

      if (menu.key === 'logout') {
         setModal({ ...modal, logout: !modal.logout });
         return;
      } else if (menu.key === 'ubah-pin') {
         setModal({ ...modal, pin: !modal.pin });
         return;
      }
      location.pathname !== menu.key && History.push(menu.key);
   };

   const handleOTP = () => {
      const data = {
         otp: {
            email: profile.email,
            otp_type: 'UPDATE_PIN',
         },
      };

      dispatch(setOTP({ email: dc(profile.email) }));
      requestOTP(data, (val) => dispatch(setOTP(val)));
   };

   const handleCheckPin = (pin) => {
      setCurrentPin(pin);
      const data = {
         pin: {
            pin: ec(pin),
         },
      };

      requestCheckPin(
         data,
         () => {
            setModal({ ...modal, pin: !modal.pin, otp: !modal.otp });
            handleOTP();
         },
         () => handleRefresh(),
      );
   };

   const handleUpdatePin = (pin) => {
      const data = {
         current_pin: ec(currentPin),
         update_pin: {
            pin: ec(pin),
            pin_confirmation: ec(pin),
         },
      };

      requestUpdatePin(authState.access_key, data, () => {
         setModal({ ...modal, pin: false, otp: false, update_pin: true });
         History.replace('/');
      });
   };

   return (
      <>
         <ModalConfirm
            visible={modal.logout}
            title=" Pemberitahuan Logout"
            content="Anda yakin ingin keluar dari dashboard ini ?"
            okText="Logout"
            onCancel={() => setModal({ ...modal, logout: !modal.logout })}
            onSubmit={() => requestLogout(() => dispatch(userLogout()))}
         />
         <ModalUpdatePin
            visible={modal.update_pin}
            onCancel={() =>
               setModal({ ...modal, update_pin: !modal.update_pin })
            }
         />
         <ModalPin
            visible={modal.pin}
            refresh={refresh}
            type={pinProps.type}
            mode={pinProps.mode}
            onCancel={() => setModal({ ...modal, pin: !modal.pin })}
            onSubmit={(pin) =>
               pinProps.type === 'one-time'
                  ? handleCheckPin(pin)
                  : handleUpdatePin(pin)
            }
            maskClosable={false}
         />
         <ModalOTP
            visible={modal.otp}
            type="email"
            onCancel={() => setModal({ ...modal, otp: !modal.otp })}
            onSubmit={(val) => {
               setPinProps({
                  ...pinProps,
                  type: 'two-time',
                  mode: 'setup-pin',
               });
               setModal({ ...modal, pin: !modal.pin, otp: !modal.otp });
               dispatch(setAccToken(val));
            }}
            maskClosable={false}
         />
         {!refreshMenu && (
            <TheMenu
               mode="inline"
               style={{
                  height: '100%',
                  borderRight: 0,
               }}
               items={menuItem}
               defaultSelectedKeys={[`/${path[1]}`]}
               selectedKeys={[`/${path[1]}`]}
               onClick={handleMenu}
               className="sidebar-menu"
            />
         )}
      </>
   );
};

export default Menu;
