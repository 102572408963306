import axios from 'axios';
import { getStorageToken, getToken } from './Cookies';
import handleError from './handleError';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
   failedQueue.forEach((prom) => {
      if (error) {
         prom.reject(error);
      } else {
         prom.resolve(token);
      }
   });

   failedQueue = [];
};

const restApi = axios.create({
   baseURL: process.env.REACT_APP_BASE_URL,
   timeout: process.env.REACT_APP_TIMEOUT || 10000,
   headers: {
      'Content-Type': 'application/json',
   },
});

const restApiWithoutVersion = axios.create({
   baseURL: process.env.REACT_APP_BASE_URL.replace('/merchant/v1', ''),
   timeout: process.env.REACT_APP_TIMEOUT || 10000,
   headers: {
      'Content-Type': 'application/json',
   },
});

restApiWithoutVersion.interceptors.response.use(
   function (response) {
      return response;
   },
   function (error) {
      return Promise.reject(handleError(error));
   },
);

restApiWithoutVersion.interceptors.request.use(
   async (config) => {
      if (
         config.method === 'get' ||
         config.method === 'delete' ||
         config.data === undefined
      ) {
         config.data = true;
      }
      const newConfig = config;

      return newConfig;
   },
   (error) => Promise.reject(error),
);

restApi.interceptors.response.use(
   async (config) => {
      return config;
   },
   async (error) => {
      const newConfig = error.config;
      if (error?.response?.status === 401 && !newConfig._retry) {
         if (isRefreshing) {
            return new Promise(function (resolve, reject) {
               failedQueue.push({ resolve, reject });
            })
               .then((token) => {
                  newConfig.headers['X-AUTH-TOKEN'] = token;
                  return axios(newConfig);
               })
               .catch((err) => {
                  return Promise.reject(err);
               });
         }
         newConfig._retry = true;
         isRefreshing = true;
         try {
            const token = await getStorageToken();
            if (token) {
               processQueue(null, token);
               newConfig.headers['X-AUTH-TOKEN'] = token;
               return axios(newConfig);
            }
         } catch (err) {
            processQueue(err, null);
            Promise.reject(err);
         } finally {
            isRefreshing = false;
         }
      }
      return Promise.reject(error);
   },
);

restApi.interceptors.request.use(
   async (config) => {
      if (
         config.method === 'get' ||
         config.method === 'delete' ||
         config.data === undefined
      ) {
         config.data = true;
      }
      const newConfig = config;
      const token = await getToken();
      if (config?.withoutTimeOut) {
         newConfig.timeout = 99999999;
      }
      if (token) {
         newConfig.headers['X-AUTH-TOKEN'] = token;
      }

      if (process.env.NODE_ENV === 'development') {
         console.log(newConfig);
      }
      return newConfig;
   },
   (error) => Promise.reject(error),
);

restApi.interceptors.response.use(
   function (response) {
      return response;
   },
   function (error) {
      return Promise.reject(handleError(error));
   },
);

export { restApi, restApiWithoutVersion };
