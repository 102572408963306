import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallet: {
    loading: false,
    data: [],
    page: 1,
    totalData: null,
    totalPage: null,
    error: "",
  },
  index: {
    waiting_confirmation: null,
    waiting_pickup: null,
    new_reviews: null,
  },
  notification: {
    unread: 0,
    loading: false,
    data: [],
    page: 1,
    totalData: null,
    totalPage: null,
    error: "",
  },
  product: {
    loading: false,
    data: [],
    page: 1,
    totalData: null,
    totalPage: null,
    error: "",
  },
  merchantBanks: {
    loading: false,
    data: [],
    error: "",
  },
  withdrawals: {
    loading: false,
    data: {},
    error: "",
  },
  initializer: {},
};

const homeReducer = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHome: (state, action) => {
      state.index = action.payload.sales;
    },
    setInitializer: (state, action) => {
      return {
        ...state,
        initializer: {
          ...state.initializer,
          ...action.payload,
        },
      };
    },
    setProductHomePage: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          page: action.payload,
        },
      };
    },

    CREATE_WITHDRAWALS_LOADING: (state) => {
      return {
        ...state,
        withdrawals: {
          ...state.withdrawals,
          loading: true,
        },
      };
    },
    CREATE_WITHDRAWALS_SUCCESS: (state, action) => {
      return {
        ...state,
        withdrawals: {
          ...state.withdrawals,
          loading: false,
          data: action.payload,
        },
      };
    },
    CREATE_WITHDRAWALS_FAILED: (state, action) => {
      return {
        ...state,
        withdrawals: {
          ...state.withdrawals,
          loading: false,
          error: action.payload,
        },
      };
    },

    GET_MERCHANT_BANKS_LOADING: (state, action) => {
      return {
        ...state,
        merchantBanks: {
          ...state.merchantBanks,
          loading: true,
        },
      };
    },
    GET_MERCHANT_BANKS_SUCCESS: (state, action) => {
      return {
        ...state,
        merchantBanks: {
          ...state.merchantBanks,
          loading: false,
          data: action.payload,
        },
      };
    },
    GET_MERCHANT_BANKS_FAILED: (state, action) => {
      return {
        ...state,
        merchantBanks: {
          ...state.merchantBanks,
          loading: false,
          error: action.payload,
        },
      };
    },
    GET_WALLET_LOADING: (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          loading: true,
        },
      };
    },
    GET_WALLET_SUCCESS: (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          loading: false,
          data: action.payload.data,
          page: action.payload.page,
          totalData: Number(action.payload.totalData),
          totalPage: Number(action.payload.totalPage),
        },
      };
    },
    GET_WALLET_FAILED: (state, action) => {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          loading: false,
          error: action.payload,
        },
      };
    },
    GET_NOTIFICATION_LOADING: (state, action) => {
      return {
        ...state,
        notification: {
          ...state.notification,
          loading: true,
        },
      };
    },
    GET_NOTIFICATION_SUCCESS: (state, action) => {
      return {
        ...state,
        notification: {
          ...state.notification,
          loading: false,
          data: action.payload.data,
          page: action.payload.page,
          totalData: Number(action.payload.totalData),
          totalPage: Number(action.payload.totalPage),
        },
      };
    },
    GET_NOTIFICATION_FAILED: (state, action) => {
      return {
        ...state,
        notification: {
          ...state.notification,
          loading: false,
          error: action.payload,
        },
      };
    },
    setUnreadNotification: (state, action) => {
      return {
        ...state,
        notification: {
          ...state.notification,
          unread: action.payload,
        },
      };
    },

    GET_HOME_PRODUCT_LOADING: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          loading: true,
        },
      };
    },
    GET_HOME_PRODUCT_SUCCESS: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          data: action.payload.data,
          page: action.payload.page,
          totalData: Number(action.payload.totalData),
          totalPage: Number(action.payload.totalPage),
        },
      };
    },
    GET_HOME_PRODUCT_FAILED: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          error: action.payload,
        },
      };
    },
  },
});

export const {
  setHome,
  setProductHomePage,
  setInitializer,
  CREATE_WITHDRAWALS_LOADING,
  CREATE_WITHDRAWALS_SUCCESS,
  CREATE_WITHDRAWALS_FAILED,
  GET_MERCHANT_BANKS_LOADING,
  GET_MERCHANT_BANKS_SUCCESS,
  GET_MERCHANT_BANKS_FAILED,
  GET_WALLET_LOADING,
  GET_WALLET_SUCCESS,
  GET_WALLET_FAILED,
  GET_NOTIFICATION_LOADING,
  GET_NOTIFICATION_FAILED,
  GET_NOTIFICATION_SUCCESS,
  GET_HOME_PRODUCT_LOADING,
  GET_HOME_PRODUCT_SUCCESS,
  GET_HOME_PRODUCT_FAILED,
  setUnreadNotification,
} = homeReducer.actions;

export default homeReducer.reducer;
