export const URL_UPDATE_ACCOUNT_ADDRESS = `/account/update-address`;
export const URL_UPDATE_MERCHANT = `/account/update-merchant`;
export const URL_UPDATE_PROFILE = `/account/update-profile`;
export const URL_UPDATE_PHONE = `/account/update-phone?access_key=%(access_key)s`;
export const URL_UPDATE_EMAIL = `/account/update-email?access_key=%(access_key)s`;
export const URL_UPDATE_PIN = `/account/update-pin?access_key=%(access_key)s`;
export const URL_UPDATE_AVATAR = `/account/update-avatar`;

export const URL_OTP = `/account/otp`;
export const URL_VERIFICATION_OTP = `/account/otp-verification?otp_key=%(otp_key)s`;

export const URL_GET_BANK = `/banks%(params)s`;
export const URL_VALIDATE_BANK = `/merchant-banks/inquiry`;
export const URL_GET_MERCHANT_BANK = `/merchant-banks%(params)s`;
export const URL_GET_COURIERS = `/tb-expedition/shipping/couriers`;

export const URL_CREATE_MERCHANT_BANK = `/merchant-banks`;
export const URL_DELETE_MERCHANT_BANK = `/merchant-banks/%(id)s`;
