export const URL_WITHDRAWALS = `/withdrawals`;
export const URL_SHOW_WITHDRAWAL = `/withdrawals/%(id)s`;

export const kSALE = "SALE";
export const kWITHDRAWAL = "WITHDRAWAL";
export const kGENERAL = "GENERAL";
export const kCASHBACK = "CASHBACK";
export const kTRANSACTION = "TRANSACTION";
export const kTOPUP = "TOPUP";
export const kREFERRAL = "REFERRAL";
export const kREFUND = "REFUND";
export const kREDUCE = "REDUCE";
export const kGIFT = "GIFT";
export const kTRANSFER = "TRANSFER";
export const kIN = "IN";
export const kOUT = "OUT";

export const humanizeTransactionKind = (kind, inOut = false) => {
  switch (kind) {
    case kSALE:
      return "Hasil Penjualan Produk";
    case kWITHDRAWAL:
      return "Penarikan Saldo Penghasilan";
    case kGENERAL:
      return "Umum";
    case kCASHBACK:
      return "Cashback";
    case kTRANSACTION:
      return "Pembayaran";
    case kTOPUP:
      return "Top-Up Saldo";
    case kREFERRAL:
      return "Komisi Wismart";
    case kREFUND:
      return "Pengembalian Dana";
    case kREDUCE:
      return "Pengurangan Saldo o/ Admin";
    case kGIFT:
      return "Hadiah";
    case kTRANSFER:
      if (inOut === kIN) {
        return "Transfer Masuk";
      }
      return "Transfer Keluar";

    default:
      return "";
  }
};

export const humanizeTransactionDescription = (item) => {
  switch (item?.kind) {
    case kSALE:
      return `${item?.assignable?.tb_product_cache?.name}`;
    case kWITHDRAWAL:
      return `Transfer ke Rek. ${item?.assignable?.bankable?.bank?.name}`;

    default:
      return item?.description ?? "";
  }
};

export const humanizeTransactionCode = (item) => {
  switch (item?.kind) {
    case kSALE:
      return item?.assignable?.code;

    default:
      return "";
  }
};
