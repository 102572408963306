import { isEmpty } from "lodash";

export const CARD_LIMIT = 10;
export const initialFiter = {
  limit: CARD_LIMIT,
  page: 1,
  search: "",
  order: null,
  date: "",
};

export const getParsingData = (data) => {
  if (isEmpty(data)) return null;
  return JSON.parse(data);
};
