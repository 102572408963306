import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phone: undefined,
  email: undefined,
  otp_key: undefined,
  otp_type: undefined,
  access_key: undefined,
  register: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOTP: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setAccToken: (state, action) => {
      state.access_key = action.payload;
    },
    setRegisterData: (state, action) => {
      state.register = action.payload;
    },
    resetAuth: (state) => {
      state.phone = undefined;
      state.email = undefined;
      state.otp_key = undefined;
      state.otp_type = undefined;
      state.access_key = undefined;
      state.register = undefined;
    },
    userLogout() {},
  },
});

export const { setOTP, setAccToken, setRegisterData, resetAuth, userLogout } =
  authSlice.actions;

export default authSlice.reducer;
