import React from "react";
import ReactDOM from "react-dom/client";
import "./resources/styles/style.scss";
import App from "./App";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import History from "helpers/History";
import { persistor, store } from "redux/index";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ScrollToTop from "components/general/scrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HistoryRouter history={History}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </PersistGate>
    </Provider>
  </HistoryRouter>,
);
