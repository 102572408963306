import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  province: {
    loading: false,
    data: [],
    error: "",
  },
  regency: {
    loading: false,
    data: [],
    error: "",
  },
  district: {
    loading: false,
    data: [],
    error: "",
  },
  couriers: {
    loading: false,
    data: [],
    error: "",
  },
};

const basicReducer = createSlice({
  name: "basic",
  initialState,
  reducers: {
    GET_PROVINCE_LOADING: (state, action) => {
      return {
        ...state,
        province: {
          ...state.province,
          loading: true,
        },
      };
    },
    GET_PROVINCE_SUCCESS: (state, action) => {
      return {
        ...state,
        province: {
          ...state.province,
          loading: false,
          data: action.payload,
        },
      };
    },
    GET_PROVINCE_FAILED: (state, action) => {
      return {
        ...state,
        province: {
          ...state.province,
          loading: false,
          error: action.payload,
        },
      };
    },
    GET_REGENCY_LOADING: (state, action) => {
      return {
        ...state,
        regency: {
          ...state.regency,
          loading: true,
        },
      };
    },
    GET_REGENCY_SUCCESS: (state, action) => {
      return {
        ...state,
        regency: {
          ...state.regency,
          loading: false,
          data: action.payload,
        },
      };
    },
    GET_REGENCY_FAILED: (state, action) => {
      return {
        ...state,
        regency: {
          ...state.regency,
          loading: false,
          error: action.payload,
        },
      };
    },
    GET_DISTRICT_LOADING: (state, action) => {
      return {
        ...state,
        district: {
          ...state.district,
          loading: true,
        },
      };
    },
    GET_DISTRICT_SUCCESS: (state, action) => {
      return {
        ...state,
        district: {
          ...state.district,
          loading: false,
          data: action.payload,
        },
      };
    },
    GET_DISTRICT_FAILED: (state, action) => {
      return {
        ...state,
        district: {
          ...state.district,
          loading: false,
          error: action.payload,
        },
      };
    },
    GET_COURIERS_LOADING: (state, action) => {
      return {
        ...state,
        couriers: {
          ...state.couriers,
          loading: true,
        },
      };
    },
    GET_COURIERS_SUCCESS: (state, action) => {
      return {
        ...state,
        couriers: {
          ...state.couriers,
          loading: false,
          data: action.payload,
        },
      };
    },
    GET_COURIERS_FAILED: (state, action) => {
      return {
        ...state,
        couriers: {
          ...state.couriers,
          loading: false,
          error: action.payload,
        },
      };
    },
  },
});

export const {
  GET_PROVINCE_FAILED,
  GET_PROVINCE_LOADING,
  GET_PROVINCE_SUCCESS,
  GET_REGENCY_FAILED,
  GET_REGENCY_SUCCESS,
  GET_REGENCY_LOADING,
  GET_DISTRICT_FAILED,
  GET_DISTRICT_LOADING,
  GET_DISTRICT_SUCCESS,
  GET_COURIERS_FAILED,
  GET_COURIERS_LOADING,
  GET_COURIERS_SUCCESS,
} = basicReducer.actions;

export default basicReducer.reducer;
