import { lazy } from "react";

const SignIn = lazy(() => import("containers/auth/signIn"));
const OTP = lazy(() => import("containers/auth/signIn/otp"));
const Pin = lazy(() => import("containers/auth/signIn/pin"));
const SignUp = lazy(() => import("containers/auth/signUp"));
const SetupPin = lazy(() => import("containers/auth/signUp/pin"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/sign-in",
    exact: true,
    component: SignIn,
  },
  {
    path: "/sign-in/otp",
    exact: false,
    component: OTP,
  },
  {
    path: "/sign-in/pin",
    exact: false,
    component: Pin,
  },
  {
    path: "/sign-up",
    exact: true,
    component: SignUp,
  },
  {
    path: "/sign-up/setup-pin",
    exact: false,
    component: SetupPin,
  },
];
