export const URL_GET_ORDER = `/tb-transactions%(params)s`;
export const URL_GET_ORDER_DETAIL = `/tb-transactions/%(id)s`;
export const URL_GET_ORDER_COUNTER = `/tb-transactions/counts`;
export const URL_GET_TRACKING_SHIPMENT = `/tb-transactions/%(id)s/tracking-shipments`;
export const URL_ORDER_ACCEPTS = `/tb-transactions/accepts`;
export const URL_ORDER_DECLINES = `/tb-transactions/declines`;
export const URL_REQ_PICKUPS = `/tb-expedition/shipping/pickups`;
export const URL_Check_Waybill = `/tb-expedition/shipping/check-waybill`;
export const URL_REQ_PICKUP_SUMMARIES = `/tb-expedition/shipping/pickup-summaries`;
export const URL_SHIPPING_MANUAL = `/tb-expedition/shipping/%(id)s/manual`;
export const URL_UPDATE_AWB = `/tb-expedition/shipping/%(id)s/manual/update-awb`;
export const URL_UPDATE_ORDER_STATUS = `/tb-transactions/%(id)s/status`;

export const PROCESSING = "PROCESSING";
export const WAITING_CONFIRMATION = "WAITING_CONFIRMATION";
export const WAITING_PICKUP = "WAITING_PICKUP";
export const WAITING_QUEUE = "WAITING_QUEUE";
export const SHIPPING = "SHIPPING";
export const FINISH = "FINISH";
export const CANCELED = "CANCELED";
export const COMPLAINT = "COMPLAINT";

export const TRANSIT = "TRANSIT";
export const IN = "IN";
export const OUT = "OUT";
export const PENDING = "PENDING";
export const DELIVER = "DELIVER";
export const DROP = "DROP";
export const PICKUP = "PICKUP";
export const DONE = "DONE";
export const DELIVERED = "DELIVERED";
export const ARRIVED = "ARRIVED";
export const FAILED = "FAILED";
export const PROBLEM = "PROBLEM";
export const PICKREQ = "PICKREQ";
export const ON_THE_WAY = "ON_THE_WAY";
export const ANT = "ANT";

export const cStatusTransit = "#E6E0F5";
export const cStatusTransitText = "#553CD6";
export const cStatusCanceled = "#FFE4E4";
export const cStatusCanceledText = "#FE0000";
export const cStatusDone = "#DAF5D3";
export const cStatusDoneText = "#2E9B1A";
export const cStatusPending = "#FDF4D0";
export const cStatusPendingText = "#F3A205";
export const cStatusWaitingPickup = "#FEF9C9";
export const cStatusWaitingPickupText = "#CF7C09";
export const cStatusFailed = "#F9DAB0";
export const cStatusFailedText = "#D84D00";
export const cStatusProblem = "#FFE1F2";
export const cStatusProblemText = "#FE2FA2";
export const cStatusOntheWay = "#E9EFFF";
export const cStatusOntheWayText = "#0A4DF4";
export const cStatusIn = "#CEDBFD";
export const cStatusInText = "#083EC4";
export const cStatusOut = "#FADAC9";
export const cStatusOutText = "#B21111";
export const cStatusDrop = "#FDDCF0";
export const cStatusDropText = "#940956";
export const cStatusPickup = "#FEF1AC";
export const cStatusPickupText = "#806404";
export const cStatusDeliver = "#F1F2C0";
export const cStatusDeliverText = "#3C6C28";

export const ORDER_STATUS = [
  {
    key: WAITING_CONFIRMATION,
    name: "Pesanan Baru",
    status: PROCESSING,
    is_merchant_confirm: false,
  },
  {
    key: WAITING_PICKUP,
    name: "Siap Dikirim",
    status: PROCESSING,
    is_merchant_confirm: true,
  },
  {
    key: SHIPPING,
    name: "Dalam Pengiriman",
    status: SHIPPING,
    is_complaint: false,
  },
  {
    key: FINISH,
    name: "Pesanan Selesai",
    status: FINISH,
  },
  {
    key: CANCELED,
    name: "Dibatalkan",
    status: CANCELED,
  },
  {
    key: COMPLAINT,
    name: "Barang Bermasalah",
    status: SHIPPING,
    is_complaint: true,
  },
];

export function getCancellationReason(reason) {
  switch (reason) {
    case "MERCHANT_NOT_RESPOND":
      return "Cancel by System";

    case "REPICKUP_SYSTEM_CANCELED":
      return "Cancel by System";

    case "MERCHANT_CANCELED":
      return "Cancel by Seller";

    case "UNPAID_PAYMENT":
      return "VA Expired";

    case "SLOTS_NOT_FULL":
      return "Group Expired";

    default:
      return "Cancel by Seller";
  }
}

export function getDetailCancellationReason(reason) {
  switch (reason) {
    case "MERCHANT_NOT_RESPOND":
      return "Pesanan ini telah melewati batas respon (Kedaluarsa). Silahkan hubungi helpdesk untuk bantuan";

    case "REPICKUP_SYSTEM_CANCELED":
      return "Pesanan ini gagal di pick-up oleh kurir. Silahkan hubungi helpdesk untuk bantuan";

    case "MERCHANT_CANCELED":
      return "Pesanan ini telah ditolak. Silahkan hubungi helpdesk untuk bantuan";

    default:
      return "Pesanan ini telah dibatalkan. Silahkan hubungi helpdesk untuk bantuan";
  }
}
