import { Suspense, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { route, privateRoute } from "./routes";
import ProtectedRoute from "helpers/ProtectedRoute";
import Layout from "components/auth/Layout";
import CetakLabel from "containers/order/cetakLabel";
import ModalReminder from "components/auth/Home/modalReminder";
import { useDispatch, useSelector } from "react-redux";
import History from "helpers/History";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment/locale/id";
import { LiveChatWidget } from "@livechat/widget-react";
import { dc } from "helpers/Crypto";
import { onMessageListener } from "helpers/Notification";

function App(props) {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [isModal, setModal] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState("minimized");
  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  useEffect(() => {
    // without this line it didn't work
    moment.locale("id");
    onMessageListener();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      !isEmpty(profile.data) &&
      location.pathname !== "/pengaturan-toko/ubah-alamat/alamat-baru"
    ) {
      !profile.onboarding &&
        isEmpty(profile.data?.address) &&
        setModal(!isModal);
    }
  }, [location, profile]); // eslint-disable-line

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        {route.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            element={
              <Suspense fallback={<div>loading...</div>}>
                <item.component
                  dispatch={dispatch}
                  authState={authState}
                  {...props}
                />
              </Suspense>
            }
          />
        ))}
        {privateRoute.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            element={
              <Layout>
                <ModalReminder
                  visible={isEmpty(profile.data?.address) && isModal}
                  onClick={() => {
                    History.push("/pengaturan-toko/ubah-alamat/alamat-baru");
                    setModal(!isModal);
                  }}
                />

                <LiveChatWidget
                  license={"11551183"}
                  visibility={isChatVisible}
                  customerName={profile?.data?.name}
                  customerEmail={
                    profile?.data?.email ? dc(profile?.data?.email) : ""
                  }
                  onVisibilityChanged={(event) =>
                    setIsChatVisible(event.visibility)
                  }
                />
                <Suspense fallback={<div>loading...</div>}>
                  <item.component
                    dispatch={dispatch}
                    setIsChatVisible={setIsChatVisible}
                    {...props}
                  />
                </Suspense>
              </Layout>
            }
          />
        ))}
        <Route path="/cetak-label" element={<CetakLabel />} />
      </Route>
      <Route path="*" element={<div>404 page</div>} />
    </Routes>
  );
}

export default App;
