import {
  URL_GET_MERCHANT_WALLETS,
  URL_GET_HOME_PROPERTY,
  URL_GET_NOTIFICATION,
  URL_GET_UNREAD_NOTIFICATION,
  URL_READ_ALL_NOTIFICATION,
  URL_GET_MERCHANT_BANKS,
  URL_GET_INITIALIZER,
  URL_READ_NOTIFICATION,
  URL_GET_MERCHANT_WALLETS_REPORT,
} from "constants/home";
import { restApi, restApiWithoutVersion } from "helpers/Api";
import { sprintf } from "sprintf";
import { parseLinkHeader } from "@web3-storage/parse-link-header";
import { message } from "antd";
import { dispatch } from "../index";
import {
  CREATE_WITHDRAWALS_FAILED,
  CREATE_WITHDRAWALS_LOADING,
  CREATE_WITHDRAWALS_SUCCESS,
  GET_MERCHANT_BANKS_FAILED,
  GET_MERCHANT_BANKS_LOADING,
  GET_MERCHANT_BANKS_SUCCESS,
  GET_WALLET_FAILED,
  GET_WALLET_LOADING,
  GET_WALLET_SUCCESS,
  setInitializer,
} from "redux/reducer/home";
import { showErrorMessage } from "helpers/handleError";
import { URL_SHOW_WITHDRAWAL, URL_WITHDRAWALS } from "constants/withdrawal";

export const requestGetMerchantWallet = (action) => {
  dispatch(GET_WALLET_LOADING());
  restApi
    .get(sprintf(URL_GET_MERCHANT_WALLETS, { params: action?.params || "" }))
    .then((response) => {
      const { data } = response.data;
      const totalPage = response.headers.link
        ? parseLinkHeader(response.headers.link)?.last?.page
        : 1;
      const totalData = response.headers["x-total"] || 0;
      dispatch(
        GET_WALLET_SUCCESS({ data, totalPage, totalData, page: action?.page }),
      );
    })
    .catch((error) => {
      message.error(error.message);
      dispatch(GET_WALLET_FAILED(error.message));
    });
};

export const requestReportMerchantWallet = (action) => {
  restApi
    .get(
      sprintf(URL_GET_MERCHANT_WALLETS_REPORT, {
        params: action?.params || "",
      }),
    )
    .then((response) => {
      const { data } = response.data;
      message.success(data?.message);
    })
    .catch((error) => {
      message.error(error.message);
    });
};

export const requestGetMerchantBanks = () => {
  dispatch(GET_MERCHANT_BANKS_LOADING());
  restApi
    .get(URL_GET_MERCHANT_BANKS)
    .then((response) => {
      const { data } = response.data;

      dispatch(GET_MERCHANT_BANKS_SUCCESS(data));
    })
    .catch((error) => {
      message.error(error.message);
      dispatch(GET_MERCHANT_BANKS_FAILED(error?.message));
    });
};

export const requestCreateWithdrawals = (body, onFinish, onFailed) => {
  dispatch(CREATE_WITHDRAWALS_LOADING());
  restApi
    .post(URL_WITHDRAWALS, body)
    .then((response) => {
      const { data } = response.data;
      dispatch(CREATE_WITHDRAWALS_SUCCESS(data));
      onFinish();
    })
    .catch((error) => {
      message.error(showErrorMessage(error));
      dispatch(CREATE_WITHDRAWALS_FAILED(error.message));
      onFailed(showErrorMessage(error));
    });
};

export const requestGetWithdrawal = (id) => {
  dispatch(CREATE_WITHDRAWALS_LOADING());
  restApi
    .get(sprintf(URL_SHOW_WITHDRAWAL, { id }))
    .then((response) => {
      const { data } = response.data;
      dispatch(CREATE_WITHDRAWALS_SUCCESS(data));
    })
    .catch((error) => {
      message.error(showErrorMessage(error));
      dispatch(CREATE_WITHDRAWALS_FAILED(error.message));
    });
};

export const requestGetInitializer = () => {
  restApiWithoutVersion
    .get(URL_GET_INITIALIZER, {
      withoutVersion: true,
    })
    .then((response) => {
      const { data } = response.data;
      dispatch(setInitializer(data?.applications?.default_value));
    });
};

export const requestGetHomeProperty = (onFinish) => {
  restApi
    .get(URL_GET_HOME_PROPERTY)
    .then((response) => {
      const { data } = response.data;
      onFinish(data.summary);
    })
    .catch((error) => message.error(error.message));
};

export const requestGetNotification = (action, setNotification, onFail) => {
  restApi
    .get(sprintf(URL_GET_NOTIFICATION, { params: action?.params || "" }))
    .then((response) => {
      const { data } = response.data;
      const totalPage = response.headers.link
        ? parseLinkHeader(response.headers.link)?.last?.page
        : 1;
      const totalData = response.headers["x-total"] || 0;

      setNotification({ data, totalPage, totalData, page: action?.page });
    })
    .catch((error) => {
      onFail();
      message.error(error.message);
    });
};

export const requestReadNotification = (id) => {
  restApi.get(sprintf(URL_READ_NOTIFICATION, { id }));
};

export const requestGetUnreadNotification = (onFinish) => {
  restApi
    .get(sprintf(URL_GET_UNREAD_NOTIFICATION))
    .then((response) => {
      const { unread_count } = response.data?.data;
      onFinish(unread_count);
    })
    .catch((error) => message.error(error.message));
};

export const requestReadAllNotification = (onFinish) => {
  restApi
    .put(sprintf(URL_READ_ALL_NOTIFICATION))
    .then(() => {
      onFinish();
      message.success("Berhasil menandai baca semua notifikasi");
    })
    .catch((error) => message.error(error.message));
};
