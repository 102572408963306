import React from "react";
import Barcode from "react-barcode/lib/react-barcode";
import LogoWispay from "resources/img/logo-wispay.png";
import BrandSiCepat from "resources/img/brand-sicepat.png";
import { Button, Col, Row, Typography } from "antd";
import { useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { dc } from "helpers/Crypto";
import { remove62 } from "helpers/Number";
const { Text } = Typography;

const CetakLabel = () => {
  const location = useLocation();
  function replaceWithBr(note) {
    return note.replace(/\n/g, "<br />");
  }
  return (
    <Row className="section-list-label">
      {location?.state?.datas?.map((item, index) => (
        <Row key={index} className="card-label">
          <Row className="header">
            <img src={LogoWispay} alt="logo" className="logo" />
            <img src={BrandSiCepat} alt="brand sicepat" className="brand" />
          </Row>
          <Row className="section-barcode-awb">
            <Barcode
              value={item?.expedition_awb ?? ""}
              width={2}
              fontSize={14}
              height={40}
            />
          </Row>
          <Row className="section-layanan">
            <Text strong style={{ margin: "3px 0" }}>
              Jenis Layanan: {item?.expedition_service}
            </Text>
          </Row>
          <Row className="section-barcode-2nd">
            <Col className="barcode">
              <QRCode
                size={120}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={item?.expedition_order_id}
                viewBox={`0 0 120 120`}
              />
            </Col>
            <Row className="section-3Row">
              <Text strong>Asuransi : Rp.0,-</Text>
              <Text strong>
                Berat&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                {item?.tb_product_cache?.weight * item?.quantity} KG
              </Text>
              <Text strong>Quantity : {item?.quantity} Pcs</Text>
            </Row>
          </Row>
          <Row className="section-info">
            <Row className="user">
              <Text strong>Penerima:</Text>
              <Text>
                Nama: {item?.outlet_address_shipment_cache?.recipient_name}
              </Text>
              {item?.outlet_address_shipment_cache?.address != null && (
                <Text style={{ lineHeight: 1.2 }}>
                  {dc(item?.outlet_address_shipment_cache?.address)}{" "}
                  {item?.outlet_address_shipment_cache?.note &&
                    `(${item?.outlet_address_shipment_cache?.note})`}
                </Text>
              )}
              {item?.outlet_address_shipment_cache?.phone != null && (
                <Text style={{ marginTop: "auto" }}>
                  Nomor Telepon:{" "}
                  {remove62(dc(item?.outlet_address_shipment_cache?.phone))}
                </Text>
              )}
            </Row>
            <Row className="user">
              <Text strong>Pengirim:</Text>
              <Text>Nama: {item?.tb_product?.merchant?.store_name}</Text>
              <Text style={{ lineHeight: 1.2 }}>
                {item?.merchant_address_cache?.address}
              </Text>
              <Text style={{ marginTop: "auto" }}>
                Nomor Telepon:{" "}
                {item?.tb_product?.merchant?.phone == null
                  ? "-"
                  : remove62(dc(item?.tb_product?.merchant?.phone))}
              </Text>
            </Row>
          </Row>
          <Row className="section-catatan">
            <Text strong>
              Catatan:{" "}
              <p
                style={{ marginBottom: 0, fontWeight: "400" }}
                dangerouslySetInnerHTML={{
                  __html: replaceWithBr(item?.checkout_note),
                }}
              />{" "}
            </Text>
          </Row>
        </Row>
      ))}

      <Button
        className="btn-gradient"
        style={{ maxWidth: 140 }}
        onClick={() => {
          const ids = location?.state?.datas.map((item) => item.code);
          document.title = `${ids.join(", ")}`;
          window.print();
          return false;
        }}
      >
        Cetak Label
      </Button>
    </Row>
  );
};

export default CetakLabel;
