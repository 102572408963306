import React, { useEffect, forwardRef } from 'react';
import ReactCodeInput from 'react-code-input';

const PinInput = forwardRef((props, ref) => {
   const { onChange, className, refresh, fields, inputStyle, type } = props;

   useEffect(() => {
      if (!refresh) {
         const reactCodeStyle = document.querySelector(`.${className}`);
         reactCodeStyle.style = null;
      }
   }, [refresh]); // eslint-disable-line

   return (
      <div>
         {!refresh && (
            <ReactCodeInput
               fields={fields}
               type={type}
               inputMode="number"
               inputStyle={inputStyle}
               onChange={(val) => onChange(val)}
               className={className}
               filterChars={[
                  'a',
                  'b',
                  'c',
                  'd',
                  'e',
                  'f',
                  'g',
                  'h',
                  'i',
                  'j',
                  'k',
                  'l',
                  'm',
                  'n',
                  'o',
                  'p',
                  'q',
                  'r',
                  's',
                  't',
                  'u',
                  'v',
                  'w',
                  'x',
                  'y',
                  'z',
                  '[',
                  ']',
                  ';',
                  "'",
                  ',',
                  '.',
                  '-',
                  '=',
                  '/',
               ]}
               ref={ref}
            />
         )}
      </div>
   );
});

export default PinInput;
