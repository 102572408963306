import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { privateRoute, route } from "routes";
import { getToken } from "./Cookies";

const ProtectedRoute = () => {
  const location = useLocation();
  const token = getToken();

  if (token) {
    return route.find((item) => item.path === location.pathname) ? (
      <Navigate to="/" />
    ) : (
      <Outlet />
    );
  } else {
    return privateRoute.find((item) => item.path === location.pathname) ? (
      <Navigate to="/sign-in" />
    ) : (
      <Outlet />
    );
  }
};

export default ProtectedRoute;
