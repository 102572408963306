import {
  URL_AUTH_OTP,
  URL_RESEND_AUTH_OTP,
  URL_SIGN_IN,
  URL_SIGN_OUT,
  URL_SIGN_UP,
  URL_VERIFICATION_AUTH_OTP,
} from "constants/auth";
import { restApi } from "helpers/Api";
import { message } from "antd";
import { dc } from "helpers/Crypto";
import { sprintf } from "sprintf";
import { removeTokenFromStorage, setTokenToStorage } from "helpers/Cookies";
import { isEmpty } from "lodash";

export const requestAuthOTP = (data, onFinish) => {
  restApi
    .post(sprintf(URL_AUTH_OTP), data)
    .then((response) => {
      const { otp_key, otp_type } = response.data.data;
      message.success(
        `Berhasil mengirim kode otp ke ${dc(data.otp_auth.phone).replace(
          "+628",
          "08",
        )}`,
      );
      onFinish({
        otp_key: otp_key,
        otp_type: otp_type,
        phone: dc(data.otp_auth.phone).replace("+628", "08"),
      });
    })
    .catch((error) => {
      message.error(error.message);
    });
};

export const requestVerificationAuthOTP = (data, otp_key, onFinish, onFail) => {
  restApi
    .post(sprintf(URL_VERIFICATION_AUTH_OTP, { otp_key }), data)
    .then((response) => {
      const { access_key } = response.data.data;
      message.success("Berhasil Verifikasi Kode OTP");
      onFinish(access_key);
    })
    .catch((error) => {
      onFail();
      message.error(error.message);
    });
};

export const requestResendAuthOTP = (data, otp_key) => {
  restApi
    .post(sprintf(URL_RESEND_AUTH_OTP, { otp_key }), data)
    .then(() => {
      message.success(
        `Berhasil mengirim ulang kode otp ke ${dc(
          data.resend_otp.phone,
        ).replace("+628", "08")}`,
      );
    })
    .catch((error) => {
      message.error(error.message);
    });
};

export const requestLogin = (fcmToken, data, access_key, onFinish, onFail) => {
  restApi
    .post(sprintf(URL_SIGN_IN, { access_key }), data, {
      headers: {
        "FCM-Device": "WEB",
        "FCM-Token": fcmToken || undefined,
      },
    })
    .then((response) => {
      const { data } = response.data;
      message.success("Login Berhasil");

      setTokenToStorage(data);
      isEmpty(localStorage.getItem("onboarding")) &&
        localStorage.setItem("onboarding", true);
      onFinish();
    })
    .catch((error) => {
      onFail();
      message.error(error.message);
    });
};

export const requestRegister = (fcmToken, data, access_key, onFinish) => {
  restApi
    .post(sprintf(URL_SIGN_UP, { access_key }), data, {
      headers: {
        "FCM-Device": "WEB",
        "FCM-Token": fcmToken || undefined,
      },
    })
    .then((response) => {
      const { data } = response.data;
      message.success("Pembuatan akun berhasil");
      setTokenToStorage(data);
      isEmpty(localStorage.getItem("onboarding")) &&
        localStorage.setItem("onboarding", true);
      onFinish();
    })
    .catch((error) => {
      message.error(error.message);
    });
};

export const requestLogout = (onFinish) => {
  restApi
    .delete(URL_SIGN_OUT)
    .then(() => {
      removeTokenFromStorage();
      window.location.reload();
      onFinish();
    })
    .then(() => message.success("Logout Berhasil"));
};
