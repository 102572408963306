import React from "react";
import IconWallet from "resources/img/icon-wallet.png";
import { Layout, Row, Typography } from "antd";
import { formatPrice } from "helpers/Number";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const { Sider } = Layout;
const { Text } = Typography;

const Sidebar = (props) => {
  const { refresh, handleRefresh } = props;
  const profile = useSelector((state) => state.profile);

  return (
    <Sider width={240}>
      <Row className="user-info">
        <img src={profile.data.avatar?.url} alt="Avatar User" />
        <Row style={{ flexDirection: "column" }}>
          <Text className="username">{profile.data.store_name}</Text>
          <Link to="/pengaturan-toko/ubah-alamat" onClick={handleRefresh}>
            <Row className="address">
              <i className="fas fa-map-marker-alt" />
              <Text className="text-grey text-address">
                {profile.data.district?.regency?.name
                  ?.replace("KOTA ", "")
                  ?.replace("KABUPATEN ", "") || "-"}
              </Text>
            </Row>
          </Link>
        </Row>
      </Row>
      <Link to="/my-wallet" onClick={handleRefresh}>
        <Row className="card-saldo">
          <Row align="middle" style={{ gap: 5 }}>
            <img src={IconWallet} alt="Icon Wallet" className="icon-wallet" />
            <Text>Saldo</Text>
          </Row>
          <Text className="saldo">{formatPrice(profile.wallet)}</Text>
        </Row>
      </Link>
      <Menu refreshMenu={refresh} handleRefreshMenu={handleRefresh} />
    </Sider>
  );
};

export default Sidebar;
