import React from "react";
import VisualReminder from "resources/img/visual-reminder.png";
import { Button, Modal, Row, Typography } from "antd";
const { Text } = Typography;

const ModalReminder = (props) => {
  const { visible, onClick } = props;

  return (
    <Modal
      title="Isi Alamat Kamu"
      visible={visible}
      footer={null}
      closable={false}
      maskClosable={false}
      className="modal-no-padding reminder"
    >
      <Row className="content-reminder">
        <img
          src={VisualReminder}
          alt="Visual Reminder"
          className="img-visual"
        />
        <Text className="desc">
          Yuk, isi alamat sekarang supaya mudahin kamu dalam berjualan.
        </Text>
        <Button className="btn-gradient" onClick={onClick}>
          Atur Alamat
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalReminder;
