import { restApi } from "./Api";
import { URL_REFRESH_TOKEN } from "constants/auth";
import { message } from "antd";
import history from "helpers/History";
export const getStorageToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");

  const merchant_token = { refresh_token: refreshToken };
  return await restApi
    .post(URL_REFRESH_TOKEN, { merchant_token })
    .then((res) => {
      const { data } = res?.data;
      setTokenToStorage({
        token: data?.token,
        refresh_token: data?.refresh_token,
      });
      return data?.token;
    })
    .catch((error) => {
      if (error?.code <= 500 && error?.code >= 400) {
        if (error?.code === 404) {
          message.error(`Koneksi Anda Bermasalah`);
          return false;
        } else {
          message.error(error?.message);
          removeTokenFromStorage();
          history.push("/sign-in");
        }
      } else {
        message.error(`Server Sedang Bermasalah`);
        return false;
      }
    });
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const setTokenToStorage = (res) => {
  const { token, refresh_token } = res;
  localStorage.setItem("token", token);
  localStorage.setItem("refresh_token", refresh_token);
};

export const removeTokenFromStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
};
