import { lazy } from "react";

const Product = lazy(() => import("containers/product"));
const CreateProduct = lazy(() => import("containers/product/create"));
const DetailProduct = lazy(() => import("containers/product/detail"));

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/daftar-produk",
    component: Product,
  },
  {
    path: "/tambah-produk",
    component: CreateProduct,
  },
  {
    path: "/daftar-produk/ubah-produk/:id",
    component: DetailProduct,
  },
];
