export const URL_GET_PROVINCE = `/provinces%(params)s`;
export const URL_GET_REGENCY = `/regencies%(params)s`;
export const URL_GET_DISTRICT = `/districts%(params)s`;

export const URL_GET_REGENCY_FROM_PROVINCE = `/provinces/%(id)s/regencies%(params)s`;
export const URL_GET_DISTRICT_FROM_REGENCY = `/regencies/%(id)s/districts%(params)s`;

export const URL_CHECK_PHONE = `/validate/merchant/phone`;
export const URL_CHECK_EMAIL = `/validate/merchant/email`;
export const URL_CHECK_PIN = `/validate/merchant/pin`;
