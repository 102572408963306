import React, {
   forwardRef,
   useEffect,
   useImperativeHandle,
   useRef,
   useState,
} from 'react';
import IconPin from 'resources/img/icon-pin.png';
import { Col, message, Modal, Row, Spin, Typography } from 'antd';
import PinInput from './pinInput';
const { Text } = Typography;

const ModalPin = forwardRef((props, ref) => {
   const {
      visible,
      type = 'two-time',
      mode = 'normal',
      refresh,
      onSubmit,
      loading,
      title,
      description,
   } = props;
   const [pin, setPin] = useState('');
   const [confirmPin, setConfirmPin] = useState('');
   const [refreshPin, setRefreshPin] = useState(false);
   const [status, setStatus] = useState('pin');
   const pinRef = useRef();

   const handleRefreshPin = () => {
      setRefreshPin(true);
      setTimeout(() => {
         setRefreshPin(false);
      }, 50);
   };

   useEffect(() => {
      if (!refreshPin) {
         pinRef?.current?.textInput.forEach((item) => {
            item.value !== ''
               ? (document.getElementById(item.id).style.backgroundColor =
                    '#999999')
               : (document.getElementById(item.id).style.backgroundColor =
                    '#DDDDDD');
         });
      }
   }, [pin, confirmPin]); // eslint-disable-line

   useEffect(() => {
      setPin('');
      setConfirmPin('');
      setStatus('pin');
      handleRefreshPin();
   }, [visible]);

   useEffect(() => {
      setPin('');
      setConfirmPin('');
      setStatus('pin');
      setRefreshPin(refresh);
   }, [refresh]);

   useImperativeHandle(ref, () => ({
      refreshPin: () => {
         setPin('');
         setConfirmPin('');
         setStatus('pin');
         handleRefreshPin();
      },
   }));

   useEffect(() => {
      if (status === 'pin') {
         if (pin.length === 6) {
            if (type === 'two-time') {
               setStatus('confirm');
               handleRefreshPin();
            } else {
               onSubmit(pin);
            }
         }
      }

      if (status === 'confirm') {
         if (confirmPin.length === 6) {
            if (pin === confirmPin) {
               onSubmit(pin);
            } else {
               message.error(
                  'Pastikan PIN yang anda input sama dengan sebelumnya',
               );
               setStatus('pin');
               setPin('');
               setConfirmPin('');
               handleRefreshPin();
            }
         }
      }
   }, [pin, confirmPin, status]); // eslint-disable-line

   return (
      <Modal
         title={
            title || mode === 'normal'
               ? status === 'pin'
                  ? 'Masukan PIN'
                  : 'Konfirmasi PIN'
               : status === 'pin'
               ? 'Masukan PIN Baru'
               : 'Konfirmasi PIN Baru'
         }
         open={visible}
         footer={null}
         className="modal-no-padding"
         width={400}
         {...props}
      >
         <Spin spinning={loading || false}>
            <Row
               align="middle"
               style={{ margin: '0 auto', flexDirection: 'column' }}
            >
               <img
                  src={IconPin}
                  alt="Icon Pin"
                  style={{ width: 80, height: 80, marginBottom: 10 }}
               />
               <Text>
                  {description || 'Masukan PIN Keamananmu untuk lanjut login'}
               </Text>
               <Row justify="center" style={{ margin: '40px 0', width: '65%' }}>
                  <Col span={24}>
                     <PinInput
                        fields={6}
                        type="password"
                        inputStyle={{
                           fontFamily: 'Source Sans Pro, sans-serif',
                           width: '18px',
                           height: '18px',
                           border: '1px solid #DDDDDD',
                           borderRadius: '100%',
                           fontSize: '14px',
                           textAlign: 'center',
                           color: 'transparent',
                        }}
                        refresh={refreshPin}
                        ref={pinRef}
                        onChange={status === 'pin' ? setPin : setConfirmPin}
                        className="pin-auth"
                     />
                  </Col>
               </Row>
            </Row>
         </Spin>
      </Modal>
   );
});

export default ModalPin;
