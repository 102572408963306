import React from "react";
import IconHome from "resources/img/icon-home.png";
import IconProduct from "resources/img/icon-product.png";
import IconPesanan from "resources/img/icon-pesanan.png";
import IconReview from "resources/img/icon-star.png";
import IconSetting from "resources/img/icon-setting.png";
import IconLogout from "resources/img/icon-logout.png";

const menuItem = [
  {
    key: "/",
    icon: (
      <img
        src={IconHome}
        alt="Icon Home"
        style={{ width: 20, height: 20, objectFit: "contain" }}
      />
    ),
    label: "Home",
  },
  {
    key: "produk",
    icon: (
      <img
        src={IconProduct}
        alt="Icon Home"
        style={{ width: 20, height: 20, objectFit: "contain" }}
      />
    ),
    label: "Produk",
    children: [
      {
        key: "/tambah-produk",
        icon: "",
        label: "Tambah Produk",
      },
      {
        key: "/daftar-produk",
        icon: "",
        label: "Daftar Produk",
      },
    ],
  },
  {
    key: "/daftar-pesanan",
    icon: (
      <img
        src={IconPesanan}
        alt="Icon Home"
        style={{ width: 20, height: 20, objectFit: "contain" }}
      />
    ),
    label: "Pesanan",
  },
  {
    key: "/ulasan",
    icon: (
      <img
        src={IconReview}
        alt="Icon Home"
        style={{ width: 20, height: 20, objectFit: "contain" }}
      />
    ),
    label: "Ulasan",
  },
  {
    key: "setting",
    icon: (
      <img
        src={IconSetting}
        alt="Icon Home"
        style={{ width: 20, height: 20, objectFit: "contain" }}
      />
    ),
    label: "Pengaturan",
    children: [
      {
        key: "/pengaturan-toko",
        icon: "",
        label: "Pengaturan Toko",
      },
      {
        key: "/pengaturan-akun",
        icon: "",
        label: "Pengaturan Akun",
      },
      {
        key: "ubah-pin",
        icon: "",
        label: "Ubah Pin",
      },
    ],
  },
  {
    key: "logout",
    icon: (
      <img
        src={IconLogout}
        alt="Icon Home"
        style={{ width: 20, height: 20, objectFit: "contain" }}
      />
    ),
    label: "Log out",
  },
];

export { menuItem };
